import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isValid, isDirty, getFormValues } from 'redux-form';
import PropTypes from 'prop-types';
import { Select, MenuItem, Tabs, Tab } from '@material-ui/core';
import { setFormContentType } from '../../actions/reading.panel.actions';
import { AvatarWrapper } from '../Avatar/AvatarWrapper';
import { WORDOFTHEWEEK, SUTRA, PRASANG, STORY } from '../../constants/contentTypes';
import ContentForm from './ContentForm';
import { WordOfTheDayTemplate, SutraTemplate, PrasangTemplate, InspiringStoryTemplate } from '../../constants/readingTemplates';
import ReadingCreateFooter from './ReadingFooter.Create';
import ReadingManageFooter from './ReadingFooter.Manage';
import { READING_FORM } from '../../constants/forms';
import * as INTENT from '../../constants/panelIntents';
import icon_close from '../../assets/icons/icon_cross.svg';

class ReadingPanel extends Component {
  constructor (props) {
    super(props);
    this.state = {
      selectedContentType: WORDOFTHEWEEK,
      contentTypes: [WORDOFTHEWEEK, SUTRA, PRASANG, STORY],
      activeTab: 0
    };
  }

  componentDidMount () {
    if (this.props.initialValues) {
      const option = this.state.contentTypes.find(type => type.value === this.props.initialValues.type);
      this.setState({ selectedContentType: option }, this.props.setFormContentType(option));
    }
  }

  /**
   * render <Select> for content type and handle select change
   */
  renderSelect = () => {
    const { selectedContentType, contentTypes } = this.state;

    const handleSelectChange = (event) => {
      const { value } = event.target;
      const contentType = this.state.contentTypes.find(type => type.value === value);
      this.setState({ selectedContentType: contentType }, this.props.setFormContentType(contentType));
    };
    const classes = ['form-field-text', 'padding-xsm-left'];
    if (this.props.initialValues) {
      classes.push('disabled-input');
    }
    return (
      <div className='form-field-text-container'>
        <Select
          MenuProps={{
            style: {
              marginTop: '0.5rem'
            },
            MenuListProps: {
              disablePadding: true
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
          disabled={!!this.props.initialValues}
          onChange={handleSelectChange}
          className={classes.join(' ')}
          disableUnderline
          value={selectedContentType.value}>
          {contentTypes.map((option, index) => {
            return (
              <MenuItem
                key={index}
                value={option.value}>{option.name}</MenuItem>
            );
          })}
        </Select>
      </div>
    );
  }

  getInitialValues = () => {
    const { selectedContentType } = this.state;
    let initialValues;
    if (this.props.initialValues) {
      initialValues = this.props.initialValues;
    } else {
      switch (selectedContentType) {
        case WORDOFTHEWEEK:
          initialValues = WordOfTheDayTemplate;
          break;
        case SUTRA:
          initialValues = SutraTemplate;
          break;
        case PRASANG:
          initialValues = PrasangTemplate;
          break;
        case STORY:
          initialValues = InspiringStoryTemplate;
          break;
        default:
          initialValues = null;
      }
    }
    return initialValues;
  }

  renderForm = () => {

    return <ContentForm
      initialValues={this.getInitialValues()}
      language={this.state.activeTab}
      type={this.state.selectedContentType}
      readOnly={this.props.intent === INTENT.MANAGE} />;
  }

  renderLanguageTabs = () => {
    const { activeTab } = this.state;
    const tabValues = ['English', 'Gujarati'];

    const handleTabChange = (event, activeTab) => {
      this.setState({ activeTab: activeTab });
    };

    return (
      <div className='reading-panel-tabs'>
        <Tabs
          onChange={handleTabChange}
          value={activeTab}>
          {tabValues.map((tab, index) => {
            return (
              <Tab
                key={index}
                value={index}
                disableRipple
                label={
                  <div
                    style={{
                      color: (activeTab === index) ? '#067bc2' : '#2a3d4c',
                      fontWeight: '500'
                    }}>
                    {tab}
                  </div>} />
            );
          })}
        </Tabs>
      </div>
    );
  }

  renderTopSection = () => {
    const { intent, initialValues, onClose } = this.props;
    let title = '';
    switch (intent) {
      case INTENT.CREATE:
        return (
          <div className='broadcast-panel-top-section '>
            <div className='padding-left padding-right'>
              <div className='broadcast-panel-top-row'>
                <h4 className='title-text padding-xsm-right'>New Reading</h4>
                <div className='broadcast-form-top-row-buttons'>
                  <img
                    className='broadcast-form-dismiss'
                    onClick={onClose}
                    src={icon_close} />
                </div>
              </div>
            </div>
          </div>
        );
      case INTENT.VIEW:
        title = 'View Reading';
        break;
      case INTENT.MANAGE:
        title = 'Manage Reading';
    };
    const { updateBy } = initialValues;
    return (
      <div className='broadcast-panel-top-section grey-border-bottom'>
        <div className='padding-left padding-right'>
          <div className='broadcast-panel-top-row'>
            <h4 className='title-text padding-xsm-right'>{title}</h4>
            <div className='broadcast-form-top-row-buttons'>
              <img
                className='broadcast-form-dismiss'
                onClick={onClose}
                src={icon_close} />
            </div>
          </div>
          <div className='broadcast-form-info'>
            <AvatarWrapper size={'84'} classes={['broadcast-form-avatar']} contact={updateBy ? updateBy.memberID : null} />
            { updateBy &&
              <div className='broadcast-info'>
                <span
                  className='margin-xsm-bottom'
                  style={{fontWeight: '500', fontSize: '18px'}}>{`${updateBy.firstName} ${updateBy.lastName}`}</span>
                <span className='margin-xsm-bottom'>{`${updateBy.memberID.contactDetails.email}`}</span>
                <span className='capitalize margin-xsm-bottom'>{updateBy.memberID.addressDetails.city}, <span className='uppercase margin-xsm-bottom'>{updateBy.memberID.addressDetails.state}</span></span>
              </div>
            }
          </div>
        </div>
      </div>
    );
  };

  render () {
    const { isFormDirty, isFormValid, intent, onClose } = this.props;
    let eventId = (this.props.isDevMode === 'true') ? this.props.initialValues._id : null;
    return (
      <div className='broadcast-panel'>
        <div>
          {this.renderTopSection()}
          <div className='padding-left padding-right'>
            {this.renderSelect()}
            {this.renderLanguageTabs()}
            {this.renderForm()}
            <span className='text-small-disable'>{ eventId }</span>
          </div>
        </div>

        {(intent === INTENT.CREATE ||
          intent === INTENT.VIEW) &&
          <ReadingCreateFooter
            isFormDirty={isFormDirty}
            isFormValid={isFormValid}
            onClose={onClose}
          />
        }
        {(intent === INTENT.MANAGE) &&
          <ReadingManageFooter onClose={onClose} />
        }

      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    initialValues: state.readingPanel.initialValues,
    intent: state.readingPanel.intent,
    isFormValid: isValid(READING_FORM)(state),
    isFormDirty: isDirty(READING_FORM)(state),
    currentValues: getFormValues(READING_FORM)(state),
    formSubmitted: state.readingPanel.formSubmitted,
    isDevMode: state.commonData.isDevMode
  };
}

export default connect(mapStateToProps, { setFormContentType })(ReadingPanel);

ReadingPanel.propTypes = {
  isDevMode: PropTypes.any,
  initialValues: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  currentValues: PropTypes.object,
  isFormDirty: PropTypes.bool.isRequired,
  isFormValid: PropTypes.bool.isRequired,
  intent: PropTypes.string.isRequired,
  setFormContentType: PropTypes.func.isRequired
};
