import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { reduxForm, Field, Fields, formValueSelector, change } from 'redux-form';
import { BROADCAST_FORM } from '../../constants/forms';
import validate from './validate';
import {
  renderTextField,
  renderDateAndTimeFields,
  renderSelectField,
  TargetSection
} from '../form-fields';
import { MdxEditorWrapper } from '../form-fields/MDXEditorWrapper';

let BroadcastForm = (props) => {

  const { initialValues, scheduleOption } = props;
  let readOnly = initialValues._id ? !!initialValues : false;
  let eventId = (props.isDevMode === 'true') ? props.initialValues._id : null;
  let sendOptions = [
    {
      name: 'Send Now',
      value: 'now'
    },
    {
      name: 'Send later',
      value: 'later'
    }
  ];
  const showScheduleOption = !readOnly;
  let dateLimit = ['', ''];
  let timeLimit = ['', ''];
  const showTime = (scheduleOption === sendOptions[1].value) || readOnly;
  if (showTime) {
    const lowerLimit = dayjs().add(10, 'm');
    const upperLimit = dayjs().add(7, 'd');
    dateLimit[0] = lowerLimit.format('YYYY-MM-DD');
    dateLimit[1] = upperLimit.format('YYYY-MM-DD');
    timeLimit[0] = lowerLimit.format('hh:mm');
    timeLimit[1] = lowerLimit.format('hh:mm');
  }
  const titleMaxChars = 140;
  const messageCharLimit = 2000;

  return (
    <div className='broadcast-form'>
      <div className='field-broadcast-target'>
        <Field
          required
          name='audiences'
          disabled={readOnly}
          component={TargetSection}
          audiencesList={props.audiencesList}
        // validate={[required, max500, min3]}
        />
      </div>
      <div
        className='field-broadcast-title'>
        <Field
          required
          readOnly={readOnly}
          maxChars={titleMaxChars}
          label={`Title (max. ${titleMaxChars} chars)`} // `
          name='title'
          component={renderTextField}
          placeholder='Enter message title' />
      </div>

      <div
        className='field-broadcast-message'>
        <Field
          required
          readOnly={readOnly}
          label={`Message (max. ${messageCharLimit} chars)`} // `
          name='message'
          component={MdxEditorWrapper}
          maxChars={messageCharLimit}
          // validate={[required, max500, min3]}
          placeholder='Enter message. Try to be precise.' />
      </div>
      <div
        className='field-broadcast-schedule'>
        <div className='form-field-label-row'>
          <label>Schedule Message<span className='form-field-required'>*</span>{showScheduleOption && scheduleOption === sendOptions[0].value ? ' (Message will be sent 10 mins from submission)' : ' (All messages will be in EST)'}</label>
        </div>
        <div>
          {showScheduleOption && <div className='field-schedule-option'>
            <Field
              required
              readOnly={readOnly}
              options={sendOptions}
              onChange={(e, newValue) => {
                if (newValue === sendOptions[1].value) {
                  const now = dayjs();
                  const minBuffer = 15;
                  const earliestSendTime = now.add(minBuffer, 'm');
                  props.change('schedule.date', earliestSendTime.format('YYYY-MM-DD'));
                  props.change('schedule.time.value', earliestSendTime.format('hh:mm'));
                  props.change('schedule.time.meridian', earliestSendTime.format('a'));
                }
              }}
              name={'schedule.when'}
              component={renderSelectField} />
          </div>}
          {showTime && <div className='field-schedule-time'>
            <Fields
              required
              readOnly={readOnly}
              names={['schedule.date', 'schedule.time']}
              dateLimit={dateLimit}
              timeLimit={timeLimit}
              component={renderDateAndTimeFields} />
          </div>}
        </div>
      </div>
      {initialValues.declinedReason &&
        <Fragment>
          <div className='horizontal-line'></div>
          <div className='comments-section'>
            <div className='form-field-label-row'>
              <label className='bold'>Comments:</label>
            </div>
            <div>
              {initialValues.declinedReason}
            </div>
          </div>
        </Fragment>}
      <span className='text-small-disable'>{eventId}</span>
    </div>
  );
};

BroadcastForm = reduxForm({
  form: BROADCAST_FORM,
  validate
})(BroadcastForm);

const selector = formValueSelector(BROADCAST_FORM);

function mapStateToProps(state) {
  return {
    scheduleOption: selector(state, 'schedule.when'),
    isDevMode: state.commonData.isDevMode,
    audiencesList: state.contactsData.contactOptions.audiencesList.broadcasts,
  };
}

export default connect(mapStateToProps, { change })(BroadcastForm);

BroadcastForm.propTypes = {
  isDevMode: PropTypes.any,
  initialValues: PropTypes.object.isRequired,
  scheduleOption: PropTypes.string,
  change: PropTypes.func.isRequired,
  targetOption: PropTypes.string
};
