import { MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import { WrappedFieldInputProps } from 'redux-form';
import { ALL_PLUGINS, ALL_PLUGINS_NO_LINK, ALL_PLUGINS_WITHOUT_TOOLBAR } from './MDXEditorPlugins';
import './MDXEditorWrapper.scss';
export interface IMdxEditorWrapper {
  label: string;
  input: WrappedFieldInputProps;
  placeholder: string;
  meta: { touched: any; error: any; warning: any; dirty: any };
  hideLinkOption?: boolean;
  readOnly: boolean;
  required: boolean;
  maxChars: number;
  name: string;
  change: (formName: string, name: string, value: string) => void;
  formName: string;
}

export const MdxEditorWrapper = (props: IMdxEditorWrapper) => {
  const {
    label,
    input,
    placeholder,
    meta: { touched, error, warning },
    readOnly,
    hideLinkOption = false,
    required,
    maxChars
  } = props;
  const classes = ['form-field-text-area', 'padding-xsm-left'];
  const charCountClasses = ['form-field-textarea-character-counter'];
  readOnly && classes.push('disabled-text-field');
  if (touched && error) {
    classes.push('form-field-text-error-state');
    charCountClasses.push('padding-bottom');
  } else {
    charCountClasses.push('padding-xsm-bottom');
  }
  const onChange = useCallback(
    (markdown: string) => {
      input.onChange(markdown);
    },
    [input]
  );
  // below gives plugins for
  const plugins = useMemo(() => {
    if (readOnly) return ALL_PLUGINS_WITHOUT_TOOLBAR;
    if (hideLinkOption) return ALL_PLUGINS_NO_LINK;
    return ALL_PLUGINS;
  }, [readOnly]);

  // The only way to reinitialize the value in the MDX editor after it is initialized is to use its ref
  // methods. In this case, if the user changes the language (or input.name), we want to change the value.
  const mdxRef = useRef<MDXEditorMethods>(null);
  useEffect(() => {
    if (mdxRef.current) {
      mdxRef.current?.setMarkdown(input.value);
    }
  }, [input.name]);

  return (
    <Fragment>
      {label && (
        <div className="form-field-label-row">
          <label>
            {label} {required && <span className="form-field-required">*</span>}
          </label>
        </div>
      )}
      <div className="form-field-text-container">
        {maxChars && (
          <div className={charCountClasses.join(' ')}>{maxChars - input.value.length}</div>
        )}
        <MDXEditor
          markdown={input?.value}
          onChange={onChange}
          contentEditableClassName={classes?.join(' ')}
          readOnly={readOnly}
          ref={mdxRef}
          placeholder={placeholder}
          plugins={plugins}
        />
        {(touched && error && <div className="form-field-error">{error}</div>) ||
          (warning && <div className="form-field-error">{warning}</div>)}
      </div>
    </Fragment>
  );
};
