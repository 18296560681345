import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { connect } from 'react-redux';
import { reduxForm, Field, FieldArray, formValueSelector, FormSection } from 'redux-form';
// material
import { Radio, FormControlLabel } from '@material-ui/core';
// PROJECT FILES
import { EVENT_FORM } from '../../constants/forms';
import validate from './validate';
import {
  renderRadioGroup,
  renderTextField,
  renderTextAreaField,
  renderSelectField,
  renderAddressField,
  renderDatePicker,
  renderScheduleFields,
  renderSectionCheckboxField,
  renderEventNumbers,
  renderImageGroup,
  TargetSection
} from '../form-fields';
import { STATES } from '../../assets/data/us_states';
import icon_radio_selected_small from '../../assets/icons/icon_radio_selected_small.svg';
import icon_radio_unselected_small from '../../assets/icons/icon_radio_unselected_small.svg';
import { MdxEditorWrapper } from '../form-fields/MDXEditorWrapper';

dayjs.extend(isSameOrBefore);

let EventsForm = (props) => {

  let eventTypeOptions = ['annual', 'monthly', 'weekly', 'one-time'];
  let eventDurationOptions = ['single day', 'multi day'];
  const { selectedValues, initialValues } = props;
  const {
    duration,
    start_date,
    end_date,
    enableTravelArrangements,
    enableCheckinInstructions
  } = selectedValues;
  let isMultiDayEvent = duration === eventDurationOptions[1];
  const eventDates = getEventDates(start_date, end_date);
  let readOnly = initialValues.id ? !!initialValues : false;
  let eventId = (props.isDevMode === 'true') ? props.initialValues.contact[0]._id : null;
  const titleMaxChars = 140;
  const descriptionMaxChars = 700;

  return (
    <div className='events-form'>
      <div className='field-event-custom-targeting'>
        <Field
          required
          name='audiences'
          disabled={readOnly}
          component={TargetSection}
          audiencesList={props.audiencesList} />
      </div>
      <div className='field-event-images'>
        <FieldArray
          label='Event Image'
          name='images'
          component={renderImageGroup}
          required
        />
      </div>
      <div
        className='field-event-name'>
        <Field
          required
          label={`Event Name (max. ${titleMaxChars} characters)`}
          name='title'
          maxChars={titleMaxChars}
          component={renderTextField}
          placeholder='Enter event title' />
      </div>
      <div
        className='field-event-description'>
        <Field
          label={`Event Description (max. ${descriptionMaxChars} characters)`}
          name='description'
          component={MdxEditorWrapper}
          maxChars={descriptionMaxChars}
          placeholder='Enter event description' />
      </div>
      <div className='horizontal-line line-1'></div>
      <div className='title-venue margin-md-bottom margin-sm-top'>
        <label className='section-label' style={{ marginRight: '0.5rem' }}>Venue / Location</label>
      </div>
      <div
        className='field-venue-name'>
        <Field
          label='Event Venue Name'
          name='venue.name'
          component={renderTextField}
          placeholder='Enter venue name'
        />
      </div>
      <div
        className='field-address-1'>
        <Field
          required
          label='Address 1'
          name='venue.address1'
          component={renderAddressField}
          placeholder='Address 1'
        />
      </div>
      <div
        className='field-address-2'>
        <Field
          label='Address 2'
          name='venue.address2'
          component={renderTextField}
          placeholder='Address 2'
        />
      </div>
      <div
        className='field-address-city'>
        <Field
          required
          label='City'
          name='venue.city'
          component={renderTextField}
          placeholder='Enter Address'
        />
      </div>
      <div className='field-address-state-zip'>
        <div
          className='field-address-state'>
          <Field
            required
            label='State'
            name='venue.state'
            component={renderSelectField}
            placeholder='State'
            options={STATES}
          />
        </div>
        <div
          className='field-address-zip'>
          <Field
            required
            label='Zip Code'
            name='venue.zip'
            component={renderTextField}
            placeholder='Zip Code'
          />
        </div>
      </div>
      <div className='horizontal-line line-2'></div>
      <div className='title-dates margin-md-bottom margin-sm-top'>
        <label className='section-label' style={{ marginRight: '0.5rem' }}>Dates</label>
      </div>
      <div className='field-event-duration'>
        <Field
          required
          label='Event Duration'
          name='duration'
          component={renderRadioGroup}>
          {eventDurationOptions.map((option, index) => {
            return (
              <FormControlLabel
                key={index}
                value={option}
                control={<Radio
                  disableRipple
                  checkedIcon={<img src={icon_radio_selected_small} />}
                  icon={<img src={icon_radio_unselected_small} />} />}
                label={option} />
            );
          })}
        </Field>
      </div>
      <div
        className='field-event-start-date'>
        <Field
          label='Event Start Date'
          name='start_date'
          required
          component={renderDatePicker}
          placeholder='mm/dd/yyyy'
        />
      </div>
      <div
        className='field-event-end-date'>
        {isMultiDayEvent && <Field
          label='Event End Date'
          name='end_date'
          format={(value) => {
            if (value && dayjs(value).isValid) {
              return dayjs(value).format('YYYY-MM-DD');
            }
          }}
          parse={(value) => {
            value = value.split('-');
            return dayjs(`${value[0]}-${value[1]}-${value[2]}`).toISOString(); // `
          }}
          readOnly={!isMultiDayEvent}
          required={isMultiDayEvent}
          component={renderDatePicker}
          placeholder='mm/dd/yyyy'
        />}
      </div>
      <div className='horizontal-line line-3' />
      <div className='field-event-schedule'>
        <div className='margin-md-bottom margin-sm-top'>
          <label className='section-label' style={{ marginRight: '0.5rem' }}>Schedule</label>
        </div>
        <FieldArray
          name='schedule'
          eventDates={eventDates}
          component={renderScheduleFields}
        />
      </div>
      <div className='horizontal-line line-4'></div>
      <div className='field-event-contact-numbers'>
        <div className='margin-md-bottom margin-sm-top'>
          <label className='section-label' style={{ marginRight: '0.5rem' }}>Important Contact Numbers</label>
        </div>
        <FieldArray
          name='contact'
          component={renderEventNumbers}
        />
      </div>

      <span className='text-small-disable'>{eventId}</span>
    </div>
  );
};

EventsForm = reduxForm({
  form: EVENT_FORM,
  validate
})(EventsForm);

const selector = formValueSelector(EVENT_FORM);
const formValuesToSelect = ['duration', 'start_date', 'end_date', 'enableTravelArrangements', 'enableCheckinInstructions'];

function mapStateToProps(state) {
  return {
    selectedValues: selector(state, ...formValuesToSelect),
    isDevMode: state.commonData.isDevMode,
    audiencesList: state.contactsData.contactOptions.audiencesList.events,
  };
}

export default connect(mapStateToProps, {})(EventsForm);

EventsForm.propTypes = {
  isDevMode: PropTypes.any,
  selectedValues: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired
};

function getEventDates(start_date, end_date) {
  if (start_date) {
    const eventDates = [];
    let currentDate = dayjs(start_date);

    if (end_date) {
      const finalDate = dayjs(end_date);
      while (currentDate.isSameOrBefore(finalDate)) {
        eventDates.push(dayjs(currentDate));
        currentDate = dayjs(currentDate).add(1, 'day');
      }
    } else {
      eventDates.push(dayjs(start_date));
    }
    return eventDates;
  } else {
    return [];
  }
}
