import axios from 'axios';
import { ROOT_URL } from '../config/config.constants';
import store from './store';
import { authActions, commonActions } from '../constants/actions';
import { SAMPARKTOKEN } from '../config/config.tokens';
import { SAMPARK } from '../constants/applications';
const http = axios.create();

http.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      store.dispatch({ type: authActions.REDIRECT_401 });
    }
    if (error.response.status === 403) {
      store.dispatch({
        type: commonActions.SHOW_HIDE_TOASTER,
        value: {
          displayToaster: true,
          message: 'Error loading contacts for selected karyakarta',
          type: commonActions.ERROR_TOASTER
        }
      });
    }
    return Promise.reject(error);
  }
);

http.interceptors.request.use(
  function (request) {
    const { headers, url } = request;
    if (url?.includes(ROOT_URL)) {
      headers.sourceapp = SAMPARK;
      headers.Authorization = 'Bearer ' + localStorage.getItem(SAMPARKTOKEN);
    }
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default http;
